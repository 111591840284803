<template>
  <div ref="map" class="map-wrap"></div>
</template>

<script>
const churuIcon = require('@/assets/images/churu.png')
export default {
  name: 'MapDemo',
  data() {
    return {
      center: [121.532022, 31.303607],
      polygons: [
        [121.531361, 31.274629],
        [121.531747, 31.274739],
        [121.532605, 31.273418],
        [121.534301, 31.274262],
        [121.534794, 31.273455],
        [121.536296, 31.274225],
        [121.536446, 31.274024],
        [121.535052, 31.273308],
        [121.536575, 31.270906],
        [121.533893, 31.26964],
        [121.531661, 31.273125],
        [121.532069, 31.273308],
        [121.531275, 31.27461]
      ],
      bianmiList: [
        {
          position: [121.541604, 31.272834],
          title: '定海所-区市场监管局'
        },
        {
          position: [121.523876, 31.272707],
          title: '控江所-区市场监管局'
        },
        {
          position: [121.549232, 31.278307],
          title: '定海街道社区事务受理服务中心'
        },
        {
          position: [121.537755, 31.270038],
          title: '欧尚超市'
        },
        {
          position: [121.532043, 31.271379],
          title: '公交137路、538路、842路、8路'
        },
        {
          position: [121.532352, 31.268574],
          title: '12号线宁国路站'
        },
        {
          position: [121.534119, 31.272581],
          title: '上海农商银行(长阳支行)'
        },
        {
          position: [121.533314, 31.269748],
          title: '中国邮政'
        },
        {
          position: [121.534681, 31.272183],
          title: '中国银行24小时自助银行'
        },
        {
          position: [121.537052, 31.270812],
          title: '公交22路、934路'
        }
      ]
    }
  },
  mounted() {
    this.handleMapInit()
  },
  methods: {
    // 地图初始化
    handleMapInit() {
      const that = this
      that.map = new window.AMap.Map(that.$refs.map, {
        mapStyle: 'amap://styles/c480a771d89530a47f51a0744bd6ab79',
        center: that.center,
        zoom: 12
      })
      new window.AMap.Marker({
        position: [121.535815, 31.270403],
        map: that.map,
        icon: churuIcon,
        angle: 60,
        offset: new window.AMap.Pixel(-14, -10)
      })
      let polygon = new window.AMap.Polygon({
        path: that.polygons,
        strokeColor: '#009be3',
        strokeWeight: 2,
        strokeOpacity: 0.6,
        fillOpacity: 0.2,
        fillColor: '#ff0000',
        zIndex: 50
      })
      that.map.add(polygon)
      // 创建纯文本标记
      new window.AMap.Text({
        text: '长阳创谷',
        anchor: 'center', // 设置文本标记锚点
        map: that.map,
        style: {
          padding: '6px 12px',
          'text-align': 'center',
          'font-size': '16px',
          color: '#999',
          border: 'none',
          'background-color': 'transparent'
        },
        position: [121.534204, 31.271584]
      })
      // 创建纯文本标记
      new window.AMap.Text({
        text: '周边配套图',
        anchor: 'center', // 设置文本标记锚点
        map: that.map,
        style: {
          padding: '6px 12px',
          'text-align': 'center',
          'font-size': '32px',
          color: '#1c85ee',
          border: 'none',
          'background-color': 'transparent',
          'font-weight': 'bolder',
          'letter-spacing': '5px'
        },
        position: [121.529282, 31.274887]
      })
      // 创建纯文本标记
      new window.AMap.Text({
        text: '出入口',
        anchor: 'center', // 设置文本标记锚点
        map: that.map,
        style: {
          padding: '6px 12px',
          'text-align': 'center',
          'font-size': '10px',
          color: '#1c85ee',
          border: 'none',
          'background-color': 'transparent',
          'font-weight': 'bolder',
          'letter-spacing': '5px'
        },
        angle: -30,
        position: [121.535605, 31.270603]
      })
      that.bianmiList.forEach(item => {
        new window.AMap.Marker({
          map: that.map,
          position: item.position,
          label: {
            content: `
          <div style="padding:3px;">
           <div style="margin-bottom:5px;font-size:14px;">${item.title}</div>
           <div style="margin-bottom:3px;display:${item.addr ? 'block' : 'none'}">地址：${item.addr}</div>
           <div style="display:${item.tel ? 'block' : 'none'}">电话：${item.tel}</div>
           <div style="display:${item.info ? 'block' : 'none'}">${item.info}</div>
          </div>
          `,
            direction: 'top'
          }
        })
      })
      that.map.setFitView()
    },
    // 销毁地图
    handleMapDestroy() {
      if (this.map) {
        this.map = null
      }
    }
  },
  beforeDestroy() {
    this.handleMapDestroy()
  }
}
</script>
<style lang="scss">
.amap-logo,
.amap-copyright {
  display: none !important;
}
.amap-scale-text {
  color: #fff;
}
.amap_lib_placeSearch {
  border-top: none;
}
.marker-wrap {
  position: relative;
  width: 260px;
  height: 70px;
  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
    border: 2px solid #ffb347;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    z-index: 667;
    &::before {
      position: absolute;
      content: '';
      bottom: -32px;
      left: 50%;
      margin: 0 0 0 -16px;
      width: 0;
      height: 0;
      border: 16px solid #ffb347;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    &::after {
      position: absolute;
      content: '';
      bottom: -28px;
      left: 50%;
      margin: 0 0 0 -14px;
      width: 0;
      height: 0;
      border: 14px solid #fff;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    .img {
      display: block;
      width: 60px;
      height: 60px;
      margin-right: 5px;
    }
    .text-wrap {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: column;
      flex: 1;
      .title {
        margin: 8px 0 5px 0;
        color: #ffb347;
      }
      .con {
        color: #999;
        font-size: 12px;
      }
    }
  }
  .icon {
    position: absolute;
    left: 50%;
    bottom: -35px;
    margin: 0 0 0 -13px;
    z-index: 666;
    width: 26px;
    height: 34px;
  }
}
</style>
<style lang="scss" scoped>
.map-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
